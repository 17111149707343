import axios, { InternalAxiosRequestConfig } from 'axios';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useAuthStore } from '@/stores/auth';
import { getLanguage } from '@/utils/helpers';
import router from '@/router';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { platformStorage } from '@/utils/local-storage';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useDev } from '@/utils/composables/useDev';

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
	authRequired?: boolean;
}

const BASE_URL = import.meta.env.VITE_API_HOST;

export const API = axios.create({
	baseURL: BASE_URL,
	timeout: 100000,
});

API.interceptors.request.use(async (config: CustomAxiosRequestConfig) => {
	const { authToken, localizationCookie } = useAppCookies();
	const { isInAppBrowser } = useMobileApp();
	const { packageVersion } = useDev();

	let locale = getLanguage(localizationCookie);
	const authRequired = config.authRequired !== false;

	if (authRequired && !authToken) {
		return Promise.reject(new Error('Token is expired or missing.'));
	}

	if (!locale) {
		locale =
			navigator.language.length > 2
				? navigator.language.slice(0, 2)
				: navigator.language;
	}

	if (config.headers) {
		config.headers['X-User-Platform'] = platformStorage.value;

		config.headers['Content-Type'] = 'application/json';

		if (locale) {
			config.headers['X-User-Locale'] = locale;
		}

		if (authToken) {
			config.headers['Authorization'] = `Bearer ${authToken}`;
		}

		if (isInAppBrowser.value) {
			config.headers['X-Inapp-Browser'] = true;
		}

		if (packageVersion.value) {
			config.headers['X-App-Version'] = packageVersion.value;
		}
	}

	const controller = new AbortController();
	config.signal = controller.signal;

	return config;
});

API.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const status = error.response?.status;
		const errorCodes = [500, 403, 401];
		const excludeRoutes = ['/auth/login', 'auth/social/callback'];
		if (status === 401 && !excludeRoutes.includes(error.config.url)) {
			const { removeAuthToken } = useAppCookies();
			removeAuthToken();
			const authStore = useAuthStore();
			await authStore.removeToken();
			await router.replace('app-login');
		}

		if (errorCodes.includes(status)) {
			notify({
				body: error.response.data.message,
			});
		}
		return Promise.reject(error);
	}
);
