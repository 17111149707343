<script setup lang="ts">
import {
	ASK_PERMISSIONS_MODAL_HEADER,
	ASK_PERMISSIONS_MODAL_IMPORTANT,
	ASK_PERMISSIONS_MODAL_MESSAGE,
	ASK_PERMISSIONS_MODAL_TITLE,
	SELECT_CHOICE,
} from '@/constants/locales';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiHandCoinsDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ImportantNote from '@/components/common/ImportantNote.vue';
import { AdvertisingId } from '@capacitor-community/advertising-id';
import { useAppStore } from '@/stores/app';
import { maidStorage } from '@/utils/local-storage';
import { TrackingEvents } from '@/enums';

const emits = defineEmits(['close-modal']);

const appStore = useAppStore();

const handleChoice = () => {
	AdvertisingId.requestTracking().then(({ value }) => {
		appStore.addLog(`TRACKING MODAL RESULT::: status - ${value}`);
		appStore.setAdvertisingStatus(value);

		if (value === 'Authorized') {
			AdvertisingId.getAdvertisingId().then(({ id }) => {
				maidStorage.value = id;
				appStore.setTracking(id).then(() => {
					appStore.trackEvent(TrackingEvents.APP_INIT);
				});
			});
		}
	});
	emits('close-modal');
};
</script>

<template>
	<div class="ask-permissions-modal">
		<ModalCommonContent centered-content>
			<template #header>{{ $t(ASK_PERMISSIONS_MODAL_HEADER) }}</template>
			<template #icon>
				<div class="modal-icon__wrapper">
					<PiHandCoinsDuotoneThin color="inherit" size="100%" />
				</div>
			</template>
			<template #title>
				<PrimeText tag="div" weight="500" align="center">
					{{ $t(ASK_PERMISSIONS_MODAL_TITLE) }}
				</PrimeText>
			</template>
			<template #message>
				<div>
					<PrimeText tag="div" size="sm" color="grey-700" align="center">
						{{ $t(ASK_PERMISSIONS_MODAL_MESSAGE) }}
					</PrimeText>

					<ImportantNote :text="$t(ASK_PERMISSIONS_MODAL_IMPORTANT)" />
				</div>
			</template>
			<template #footer>
				<PrimeButton
					type="text"
					variant="grey"
					rounded="md"
					:label="$t(SELECT_CHOICE)"
					@click="handleChoice"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.ask-permissions-modal {
	.modal-icon__wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	:deep(.p-modal-layout) {
		& .content-title,
		& .content-message {
			max-width: initial;
		}
		& div.modal-icon__wrapper svg {
			color: #141f00;
		}
	}
}
</style>
