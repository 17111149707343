import { Echo } from '@/utils/echo';
import { ref, watch, onUnmounted, computed } from 'vue';
import type {
	FeatureFlagsData,
	OfferwallTransactionComplete,
	OfferwallTransactionDelayed,
	UserBalanceUpdateInfo,
} from '@/types';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { UPDATED_BALANCE, UPDATED_PROGRESS } from '@/constants/locales';
import {
	COMPLETED_5_SURVEYS_MODAL,
	FIRST_SURVEY_COMPLETED_MODAL,
	HALF_PROGRESS_NOTIFICATION_MODAL,
	OFFER_REWARD_COMPLETE_MODAL,
	OFFER_REWARD_DELAY_MODAL,
	REFERRALS_NOTIFICATION_MODAL,
} from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { MessageChannels, TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';
import { setNotificationAsRead } from '@/api';
import { silentTransactions } from '@/views/history/views/transactions/components/transactions';

export const useAppPusherEventListener = (): {
	initPusherConnection: () => Promise<void>;
	removePusherConnection: () => void;
} => {
	const { createNewModal } = useModalStorage();
	const { t } = useI18n();
	const echo = Echo.getInstance();
	const channelName = ref('');
	const { userId } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const userStore = useUserStore();
	const appStore = useAppStore();
	const userAndToken = computed(() => userId.value + userAuthToken.value);

	const removePusherConnection = () => {
		echo.removeConnections();
	};

	const initPusherConnection = async () => {
		if (!userId.value || !userAuthToken.value) return;

		channelName.value = `user-notifications-${userId.value}`;
		if (echo.isCurrentConnection(channelName.value, userAuthToken.value)) {
			return;
		}

		removePusherConnection();

		try {
			await echo.createConnection(userAuthToken.value);
		} catch (error) {
			console.error('Error while creating WebSocket connection:', error);
		}

		window.Echo.private(channelName.value)
			.listen(
				// Progress update
				'.user-survey-taken',
				({ progress }: { progress: number }) => {
					userStore.setProgress(progress);
					notify({ body: t(UPDATED_PROGRESS) });
				}
			)
			.listen(
				// Balance update
				'.balance-updated',
				(event: UserBalanceUpdateInfo) => {
					userStore.setBalance(event.new_balance);
					userStore.setProgress(event.progress);
					if (
						!silentTransactions.includes(event.transaction_type) &&
						!event.is_silent
					) {
						createNewModal(COMPLETED_5_SURVEYS_MODAL, {
							...event,
							shared: true,
						});

						notify({ body: t(UPDATED_BALANCE) });
					}

					if (!event.is_silent) {
						notify({ body: t(UPDATED_BALANCE) });
					}
				}
			)
			.listen(
				//Show user modal on first survey complete
				'.user_first_survey_complete',
				() => {
					createNewModal(FIRST_SURVEY_COMPLETED_MODAL, { shared: true });
				}
			)
			.listen(
				//Show user modal on first survey complete
				'.one_time_intro_to_half_surveys',
				({ notification_id }: { notification_id: string }) => {
					createNewModal(HALF_PROGRESS_NOTIFICATION_MODAL, {
						id: notification_id,
						channel: MessageChannels.NOTIFICATION,
						shared: true,
						onClose: () => setNotificationAsRead(notification_id),
					});
				}
			)
			.listen(
				//Show user modal with referrals description
				'.introduce_to_referrals',
				({ notification_id }: { notification_id: string }) => {
					createNewModal(REFERRALS_NOTIFICATION_MODAL, {
						id: notification_id,
						channel: MessageChannels.NOTIFICATION,
						shared: true,
						onClose: () => setNotificationAsRead(notification_id),
					});
				}
			)
			.listen(
				'.offerwall_transaction_delayed',
				(event: OfferwallTransactionDelayed) => {
					createNewModal(OFFER_REWARD_DELAY_MODAL, {
						id: event.notification_id,
						channel: MessageChannels.NOTIFICATION,
						shared: true,
						onClose: () => setNotificationAsRead(event.notification_id),
						...event,
					});
				}
			)
			.listen(
				'.offerwall_transaction_completed',
				(event: OfferwallTransactionComplete) => {
					userStore.setBalance(event.new_balance);
					createNewModal(OFFER_REWARD_COMPLETE_MODAL, {
						id: event.notification_id,
						channel: MessageChannels.NOTIFICATION,
						shared: true,
						onClose: () => setNotificationAsRead(event.notification_id),
						...event,
					});
				}
			)
			.listen(
				//Show user modal with referrals description
				'.feature-flags-update',
				(data: FeatureFlagsData) => {
					const userStore = useUserStore();
					userStore.setFeatures(data);
				}
			)
			.listen('.double-payout-challenge-completed', () => {
				const userStore = useUserStore();
				userStore.setDoublePayoutDetails({
					...userStore.meta!.double_payout,
					active: false,
				});
			})
			.listen(
				'.gtm_track_event',
				async ({
					event_name,
					event_name_adjust,
					params,
				}: {
					event_name: TrackingEvents;
					event_name_adjust: string;
					params: Record<string, any>;
				}) => {
					appStore.addLog(
						`WS GTM TRACK EVENT:::${event_name}:::${event_name_adjust}:::${JSON.stringify(params)}`
					);
					await appStore.trackEvent(event_name, params, event_name_adjust);
				}
			);
	};

	watch(
		userAndToken,
		async () => {
			await initPusherConnection();
		},
		{ immediate: true }
	);

	onUnmounted(() => {
		removePusherConnection();
	});

	return {
		initPusherConnection,
		removePusherConnection,
	};
};
