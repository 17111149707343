import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { computed } from 'vue';
import { transformCurrency } from '@/utils/helpers';

export const useIncome = () => {
	const { fiveSurveysIncome, currency, doublePayoutsValue } =
		storeToRefs(useUserStore());

	const getTransformedValue = (value: number) =>
		transformCurrency(value || 0, currency.value, 2);

	const incomeAmount = computed(() => {
		return `${getTransformedValue(+fiveSurveysIncome.value! || 0)}`;
	});

	const doubleIncomeAmount = computed(() => {
		return `${getTransformedValue(+doublePayoutsValue.value! || 0)}`;
	});

	const doubleIncomeText = computed(
		() =>
			`<span style="text-decoration: line-through">${incomeAmount.value}</span> ${doubleIncomeAmount.value}`
	);

	return {
		incomeAmount,
		doubleIncomeAmount,
		getTransformedValue,
		doubleIncomeText,
	};
};
