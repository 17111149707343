import { watch, Ref, ref } from 'vue';
import { App as CapacitorApp } from '@capacitor/app';
import { isMobileBrowser } from '@/utils/helpers';
import { useAuthStore } from '@/stores/auth';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { storeToRefs } from 'pinia';
import { initNotifications } from '@/utils/notifications';
import { AdvertisingId } from '@capacitor-community/advertising-id';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import {
	ASK_TRACKING_PERMISSIONS_MODAL,
	UPDATE_AVAILABLE_MODAL,
} from '@/constants/modals';
import {
	appKeyStorage,
	maidStorage,
	platformStorage,
	showAlerts,
} from '@/utils/local-storage';
import { Capacitor } from '@capacitor/core';
import { Platforms, TrackingEvents } from '@/enums';
import { useDebounceFn, useNetwork } from '@vueuse/core';
import { useAppStore } from '@/stores/app';
import { useDeepLinks } from '@/utils/composables/useDeepLinks';
import { useDev } from '@/utils/composables/useDev';
import { checkVersion } from '@/api';
import { useUserStore } from '@/stores/user';
import { useRoute, useRouter } from 'vue-router';
import { USER_ROUTE_NAME } from '@/constants/routes';

export const useCapacitorPlugins = (): {
	setAdvertisingId: () => Promise<void>;
	initCapacitor: () => Promise<void>;
	isOnline: Ref<boolean>;
	deinitCapacitor: () => Promise<void>;
	setupResumeListener: (callback: () => Promise<void>) => void;
	setupPauseListener: (callback: () => Promise<void>) => void;
	isAppActive: Ref<boolean>;
} => {
	const { listenForDeeplink, isDeepLinkProcessing } = useDeepLinks();
	const authStore = useAuthStore();
	const { userAuthToken, isAuthorized } = storeToRefs(authStore);
	const { isMobileApp } = useMobileApp();
	const { createNewModal } = useModalStorage();
	const appStore = useAppStore();
	const { isQualificationInProgress, getAdvertisingStatus, isAdjoeEnabled } =
		storeToRefs(appStore);
	const { packageVersion } = useDev();
	const userStore = useUserStore();
	const { userId, isOfferwallsAvailable } = storeToRefs(userStore);
	const route = useRoute();
	const router = useRouter();

	const isAppActive = ref(true);

	const { isOnline } = useNetwork();

	watch(isOnline, (currentStatus, previousStatus) => {
		if (!isAppActive.value) return;
		if (currentStatus && !previousStatus) {
			document.location.reload();
		}
	});

	const calcPlatform = () => {
		let plt = Capacitor.getPlatform() as Platforms;
		if (plt === Platforms.WEB && isMobileBrowser()) {
			plt = Platforms.WEB_MOBILE;
		}
		return plt;
	};

	const debouncedFn = useDebounceFn(() => {
		platformStorage.value = calcPlatform();
	}, 300);

	const deinitCapacitor = async () => {
		window.removeEventListener('resize', debouncedFn);
		if (isMobileApp) {
			await CapacitorApp.removeAllListeners();
		}
	};

	const setAdvertisingId = async () => {
		if (!isMobileApp) return;

		const { status, id } = await AdvertisingId.getAdvertisingId();
		appStore.setAdvertisingStatus(status);

		if (status === 'Authorized') {
			maidStorage.value = id;
			if (showAlerts.value) {
				alert(`SET TRACKING::: status - ${status} maid - ${id}`);
			}
			await appStore.setTracking(id);
			await appStore.trackEvent(TrackingEvents.APP_INIT);
		} else if (status === 'Not Determined') {
			createNewModal(ASK_TRACKING_PERMISSIONS_MODAL, {
				useDefaultClose: false,
			});
		}
	};

	const checkAppUpdate = async () => {
		const appInfo = await CapacitorApp.getInfo();

		try {
			appStore.addLog(
				`CHECK APP VERSION::: packageVersion - ${packageVersion.value} CapacitorApp appInfo - ${appInfo.version}`
			);
			const { native_update } = await checkVersion(
				appInfo.version,
				packageVersion.value
			);

			appStore.setNativeUpdate(native_update);

			appStore.addLog(
				`CHECK APP VERSION RESPONSE::: native_update - ${native_update}`
			);

			// Native update
			if (native_update) {
				createNewModal(UPDATE_AVAILABLE_MODAL);
			}
		} catch (error) {
			console.log('checkVersion error', error);
		}
		await Promise.resolve();
	};

	const setupPauseListener = (callback: () => Promise<void>) => {
		CapacitorApp.addListener('pause', async () => {
			if (!isMobileApp) return;
			isAppActive.value = false;

			await callback();
		});
	};

	const setupResumeListener = (callback: () => Promise<void>) => {
		CapacitorApp.addListener('resume', async () => {
			if (!isMobileApp) return;
			isAppActive.value = true;
			if (!isAuthorized.value) return;
			await appStore.fetchAppMeta();

			while (isDeepLinkProcessing.value) {
				await new Promise((resolve) => setTimeout(resolve, 50));
			}

			await callback();

			if (
				(!isOfferwallsAvailable.value || !isAdjoeEnabled.value) &&
				route.name === USER_ROUTE_NAME.GAMES
			) {
				await router.replace({ name: USER_ROUTE_NAME.SURVEYS });
			}
			await appStore.initAdjoe();

			appKeyStorage.value = new Date().getTime();
		});
	};

	const setupBackButtonListener = () => {
		CapacitorApp.addListener('backButton', () => {
			if (!isQualificationInProgress.value) {
				window.history.back();
			}
		});
	};

	const initCapacitor = async () => {
		platformStorage.value = calcPlatform();
		const bodyElem = document.querySelector('body');
		if (bodyElem) {
			window.addEventListener('resize', debouncedFn);
		}
		if (isMobileApp) {
			listenForDeeplink();

			await appStore.initAdjust();
			await appStore.initAdjoe();
			await setAdvertisingId();
			checkAppUpdate().then(() => {});
			setupBackButtonListener();
			appStore.addLog('INIT CAPACITOR');
		}

		watch(
			userId,
			async (id) => {
				appStore.addLog(`USER ID CHANGED ${id}`);
				if (id && isMobileApp) {
					await appStore.initAdjoe();
					appStore.showAdjoeTeaser();
					appStore.addLog(`INIT ADJOE FOR USER ID ${id}`);
				}
			},
			{ immediate: true }
		);

		watch(
			userAuthToken,
			async (token) => {
				if (token && isMobileApp) {
					initNotifications();

					if (getAdvertisingStatus.value !== 'Authorized') {
						await setAdvertisingId();
					}
				}
			},
			{ immediate: true }
		);
	};

	return {
		initCapacitor,
		deinitCapacitor,
		isOnline,
		setAdvertisingId,
		setupResumeListener,
		setupPauseListener,
		isAppActive,
	};
};
