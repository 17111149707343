import { defineStore } from 'pinia';
import { fetchPendings, fetchUserFeatures, getUser, getUserMeta } from '@/api';
import type {
	DoublePayout,
	ErrorResponse,
	FeatureFlagsData,
	Locale,
	PendingPayouts,
	UserAccount,
	UserMeta,
} from '@/types';
import type { AxiosError } from 'axios';
import { getRootDomain } from '@/utils/helpers';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useRouter } from 'vue-router';
import { handleUserLocale } from '@/i18n';
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import { Capacitor } from '@capacitor/core';

interface UserState {
	data: UserAccount | null;
	message: string;
	loading: boolean;
	error: ErrorResponse | null;
	meta: UserMeta | null;
	initialDataLoading: boolean;
	pendings: PendingPayouts | null;
	features: FeatureFlagsData | null;
}

export const useUserStore = defineStore('user', {
	state: (): UserState => ({
		data: null,
		message: '',
		loading: false,
		error: null,
		meta: null,
		initialDataLoading: false,
		pendings: null,
		features: null,
	}),
	getters: {
		isStoreLoaded: (state) => state.data && !state.error,
		userId: (state) => state.data?.id,
		userHash: (state) => state.data?.hash,
		email: (state) => state.data?.email,
		timezone: (state) => state.data?.timezone || '',
		countryCode: (state) => state.data?.country?.code.toLowerCase() || '',
		countryName: (state) => state.data?.country?.name || '',
		createdAt: (state) => state.data?.created_at || '',
		userData: (state) => state.data,
		refLink: (state) => `${getRootDomain(true)}/register?ref=${state.data?.id}`,
		currency: (state) => state.data?.country.currency || '',
		source: (state) => state.data?.s || '',
		userLocales: (state): Locale[] => state.data?.country.locales!,
		isPasswordSet: (state) =>
			state.data && 'is_password_set' in state.data
				? state.data?.is_password_set
				: true,
		currencyBalance: (state) => state.data?.balance || 0,
		lastClaimEmail: (state) => state.data?.last_claim_email || '',
		progress: (state) => state.data?.progress || 0,
		fiveSurveysIncome: (state) => state.data?.country_payout || 0,
		userLocale: (state) => state.data?.locale || 'en-us',
		userMeta: (state) => state.meta,
		appReviewProbability: (state) => state.meta?.app_review_probability || 0,
		revolutFullName: (state) =>
			state.data?.last_revolut_counterparty.full_name || '',
		revolutTag: (state) => state.data?.last_revolut_counterparty.tag || '',
		isHalfSurveysEnabled: (state) =>
			state.meta?.is_half_surveys_enabled || false,
		isHalfSurveysUnlocked: (state) =>
			state.meta?.is_half_surveys_unlocked || false,
		isInitialDataLoading: (state) => state.initialDataLoading,
		isSupportChatAvailable: (state) => state.meta?.is_chat_available || false,
		totalPendingPayouts: (state) => state.pendings?.pending_money || 0,
		pendingPayouts: (state) => state.pendings?.pending_events || [],
		isOfferwallsAvailable: (state) =>
			!!state.features?.offer_walls?.status || false,
		isPrimeChatAvailableForUser: (state) =>
			state.data?.is_chat_available || false,
		isOfferwallsPromo: (state) => state.features?.offer_walls?.promo || null,
		isDoublePayoutsEnabled: (state) =>
			state.meta?.double_payout?.active ?? false,
		doublePayoutsValue: (state) => state.meta?.double_payout?.value ?? 0,
		doublePayoutsExpires: (state) => state.meta?.double_payout?.expire ?? 0,
		availableUserFeatures: (state) =>
			state.features ? Object.keys(state.features) : [],
		isPsOffersEnabled: (state) =>
			state.features ? !!state.features.ps_offers : false,
	},
	actions: {
		async fetchUserData() {
			try {
				this.loading = true;
				this.data = await getUser();
				await handleUserLocale(this.data.locale);
				SentryVue.setTag('user_id', this.data.id);
				if (Capacitor.isNativePlatform()) {
					Sentry.setTag('user_id', this.data.id);
				}
			} catch (error) {
				const axiosErrorResponse = (error as AxiosError).response;
				if (axiosErrorResponse?.status === 401) {
					const router = useRouter();
					await router.replace('login');
				} else {
					this.error = axiosErrorResponse?.data as ErrorResponse;
				}
				sendErrorInfo(error);
			} finally {
				this.loading = false;
			}
		},
		async fetchUserMeta() {
			try {
				this.meta = await getUserMeta();
			} catch (error) {
				console.log('Error fetching user meta', error);
			}
		},
		setTimezone(value: string): void {
			if (this.data?.timezone && this.data.timezone !== value) {
				this.data.timezone = value;
			}
		},
		setProgress(value: number): void {
			if (this.data?.progress !== undefined) {
				this.data.progress = value;
			}
		},
		setBalance(value: number): void {
			if (this.data) {
				this.data.balance = value;
			}
		},
		setInitialDataLoading(value: boolean) {
			this.initialDataLoading = value;
		},

		async fetchFeatures() {
			this.features = await fetchUserFeatures();
		},

		setFeatures(features: FeatureFlagsData) {
			this.features = features;
		},

		async fetchPendingPayouts() {
			this.pendings = await fetchPendings();
		},

		setDoublePayoutDetails(data: DoublePayout) {
			this.meta!.double_payout = data;
		},
	},
});
