<script lang="ts" setup>
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { FIVE_SURVEYS_REWARD } from '@/constants/locales';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import ProgressBar from '@/components/common/ProgressBar.vue';
import { useIncome } from '@/utils/composables/useIncome';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { isMobile } = useScreenSize();

const { progress, isDoublePayoutsEnabled } = storeToRefs(useUserStore());

const { incomeAmount, doubleIncomeAmount, doubleIncomeText } = useIncome();

const rewardText = computed(() =>
	t(FIVE_SURVEYS_REWARD, {
		value: isDoublePayoutsEnabled.value
			? doubleIncomeText.value
			: incomeAmount.value,
	})
);
</script>

<template>
	<div class="surveys-progress">
		<div class="surveys-progress-header">
			<PrimeText
				:size="isMobile ? 'sm' : 'base'"
				color="grey-300"
				v-html="rewardText"
			/>
			<PrimeText
				:size="isMobile ? 'sm' : 'lg'"
				:weight="isMobile ? '400' : '500'"
				color="white"
			>
				{{ isDoublePayoutsEnabled ? doubleIncomeAmount : incomeAmount }}
			</PrimeText>
		</div>
		<ProgressBar :progress="progress" />
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.surveys-progress {
	margin-left: auto;
	margin-right: auto;
	padding-top: 0.625rem;
	padding-bottom: 1.135rem;
	max-width: 35rem;

	@include breakpoint(mobile) {
		padding-top: 2.25rem;
		padding-bottom: 2.25rem;
	}
}

.surveys-progress-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.5rem;

	@include breakpoint(mobile) {
		margin-bottom: 0.875rem;
	}

	& .line-through {
		text-decoration: line-through;
	}
}
</style>
