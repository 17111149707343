<script lang="ts" setup>
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRoute } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';
import {
	CLAIM_REWARD,
	TRANSACTIONS_REWARDS,
	EMAIL_PREFERENCES,
	OFFERS_ACTIVITIES,
} from '@/constants/locales';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { computed } from 'vue';

const { isMobile } = useScreenSize();

withDefaults(
	defineProps<{
		backRoute?: RouteLocationRaw;
	}>(),
	{
		backRoute: undefined,
	}
);

const route = useRoute();

const titles: Record<string, string> = {
	'claim-reward': CLAIM_REWARD,
	'reward-history': TRANSACTIONS_REWARDS,
	'email-subscriptions': EMAIL_PREFERENCES,
	'recent-activity-offers': OFFERS_ACTIVITIES,
};

const pageTitle = computed(
	() => titles[route.name as keyof typeof titles] || ''
);
</script>

<template>
	<div class="page-title">
		<PrimeText :size="isMobile ? 'lg' : '2xl'" color="grey-300">
			{{ $t(pageTitle) }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
@use '@/styles/mixins.scss' as *;

.page-title {
	display: flex;
	gap: 1rem;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;
	width: 100%;
	max-width: 68.75rem;

	@include breakpoint(mobile) {
		padding-top: 2.75rem;
		padding-bottom: 2.75rem;
	}
}

.page-title-back {
	&.p-btn {
		color: var(--p-grey-300);
	}

	@include breakpoint(tablet) {
		display: none;
	}
}
</style>
