export const AUTH_ROUTE_NAME = {
	HOME: 'home',
	APP_LOGIN: 'app-login',
	SIGN_IN: 'signin',
	SIGN_UP: 'signup',
	SIGN_UP_COMPLETE: 'signup-complete',
	FORGOT_PASSWORD: 'forgot-password',
	SOCIALS: 'socials',
	AUTH_SOCIAL: 'auth-social',
	SIGN_UP_SOCIAL: 'signup-social',
	RESET_PASSWORD: 'reset-password',
	LOCALIZATION: 'localization',
};

export const USER_ROUTE_NAME = {
	SIGNATURE_LOGIN: 'signature-login',
	SURVEYS: 'surveys',
	PROFILE: 'profile',
	SETTINGS: 'settings',
	REWARD_HISTORY: 'reward-history',
	LEADERBOARD: 'leaderboard',
	CLAIM_REWARD: 'claim-reward',
	PRIZE_DRAW: 'prize-draw',
	REFERRALS: 'referrals',
	SUPPORT: 'support',
	EMAIL_SUBSCRIPTIONS: 'email-subscriptions',
	GAMES: 'games',
	PENDINGS: 'pendings',
	OFFERS: 'offers',
	RECENT_ACTIVITY_OFFERS: 'recent-activity-offers',
};

export const UTILITY_ROUTE_NAME = {
	LOGOUT: 'logout',
};
