import { inject, type InjectionKey, provide, ref, watch } from 'vue';
import { cidStorage } from '@/utils/local-storage';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { onUnmounted } from 'vue';
import { getLanguage } from '@/utils/helpers';
import { storeToRefs } from 'pinia';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';
import type { IntegrationModules } from '@/types';

export const primeSurveysInjection: InjectionKey<
	ReturnType<typeof providePrimeSurveys>
> = Symbol();

export const usePrimeSurveysIntegration = () => inject(primeSurveysInjection)!;

export const providePrimeSurveys = () => {
	const { platform } = useMobileApp();
	const { localizationCookie } = useAppCookies();
	const userStore = useUserStore();
	const authStore = useAuthStore();

	const { userId, userHash, source, currency } = storeToRefs(userStore);
	const { getAdminId } = storeToRefs(authStore);

	const language = ref(getLanguage(localizationCookie));
	const appId = ref(import.meta.env.VITE_PS_APPID);

	const integrationScriptLoading = ref(true);

	const renderPrimeSurveys = (
		container: string,
		module: IntegrationModules = 'surveys',
		moduleTitle?: string,
		tasks: string = 'surveys'
	): (() => void) =>
		onUserId(() => {
			integrationScriptLoading.value = false;

			const props = {
				container,
				module,
				moduleTitle,
				tasks,
				appId: appId.value,
				userId: userId.value,
				language: language.value || 'en',
				clickId: cidStorage.value,
				sHash: userHash.value,
				source: source.value,
				platform: platform.value,
				adminId: getAdminId.value,
				currency: currency.value,
				localization: localizationCookie || 'en-US',
				currencyLocalization: true,
			};

			// Fallback data for PS script init method
			localStorage.setItem(
				'publisher',
				JSON.stringify({
					uuid: userId.value,
					sHash: userHash.value,
					appId: appId.value,
				})
			);

			try {
				window.PrimeSurveys('render', props);
			} catch (error) {
				throw new Error(
					'PrimeSurveys render fails, PrimeSurveys is not defined'
				);
			}

			if (cidStorage.value) {
				cidStorage.value = '';
			}
		});

	const onUserId = (cb: () => void) =>
		watch(
			userId,
			(userIdValue) => {
				if (userIdValue) {
					const interval = setInterval(() => {
						if (
							typeof window.PrimeSurveys === 'function' &&
							appId.value &&
							userHash.value
						) {
							clearInterval(interval);
							cb();
						}
					}, 50);
				}
			},
			{ immediate: true }
		);

	const unmountPrimeSurveys = () => {
		if (window.PrimeSurveys !== undefined) {
			window.PrimeSurveys('unmount');
		}
	};

	onUnmounted(() => {
		unmountPrimeSurveys();
	});

	const providedData = {
		renderPrimeSurveys,
		unmountPrimeSurveys,
		integrationScriptLoading,
	};

	provide(primeSurveysInjection, providedData);

	return providedData;
};
