export const TEST_CLAIM_REWARD_MODAL = 'claim-reward-modal';
export const TEST_ACCOUNT_CHANGE_EMAIL = 'account-change-email';
export const TEST_ACCOUNT_TIMEZONE = 'account-timezone';
export const TEST_ACCOUNT_CHANGE_PASSWORD = 'account-change-password';
export const TEST_ACCOUNT_REWARD_HISTORY = 'account-reward-history';
export const TEST_ACCOUNT_RECENT_ACTIVITY_OFFERS = 'account-activity-offers';
export const TEST_ACCOUNT_REFERRALS = 'account-referrals';
export const TEST_ACCOUNT_EMAIL_PREFERENCES = 'account-email-preferences';
export const TEST_ACCOUNT_LANGUAGE = 'account-language';
export const TEST_ACCOUNT_DELETE = 'account-delete';
export const TEST_ACCOUNT_LOGOUT = 'account-logout';
export const TEST_MODAL_CLOSE = 'modal-close';
export const TEST_NEED_NEW_PASSWORD = 'need-new-password';
export const TEST_RESEND_EMAIL = 'resend-email';
export const TEST_MODAL_CHANGE_EMAIL_NEW = 'modal-change-email-new';
export const TEST_MODAL_CHANGE_EMAIL_PASSWORD = 'modal-change-email-password';
export const TEST_MODAL_CHANGE_EMAIL_CONFIRM = 'modal-change-email-confirm';
export const TEST_MODAL_TIMEZONE_SELECT = 'modal-timezone-select';
export const TEST_MODAL_TIMEZONE_SAVE = 'modal-timezone-save';
export const TEST_MODAL_CHANGE_PASSWORD_OLD = 'modal-change-password-old';
export const TEST_MODAL_CHANGE_PASSWORD_NEW = 'modal-change-password-new';
export const TEST_MODAL_CHANGE_PASSWORD_CONFIRM_NEW =
	'modal-change-password-confirm-new';
export const TEST_MODAL_CHANGE_PASSWORD_CONFIRM =
	'modal-change-password-confirm';
export const TEST_EMAIL_PREFERENCES_SAVE = 'email-preferences-save';
export const TEST_MODAL_CHANGE_LANGUAGE_SAVE = 'modal-change-language-save';
export const TEST_MODAL_DELETE_ACCOUNT_PASSWORD =
	'modal-delete-account-password';
export const TEST_MODAL_DELETE_ACCOUNT_CONFIRM = 'modal-delete-account-confirm';
export const TEST_MODAL_LOGOUT_CONFIRM = 'modal-logout-confirm';
